<template>
    <el-dialog
        width="600px"
        top="40px"
        :title="$t('keys.add_key')"
        :visible.sync="modalAddKey"
        :before-close="closeModal"
        :close-on-click-modal="false"
        @closed="clearData"
    >
        <el-row v-loading="$wait.is('loading.add_key')">
            <el-col :span="20" :offset="2" class="flex justify-center mb-5">
                <el-upload
                    ref="upload"
                    action="#"
                    :class="{ 'hide-new-file': file }"
                    list-type="picture-card"
                    :on-change="imageHandleChange"
                    :on-remove="imageHandleRemove"
                    :multiple="false"
                    accept=".jpg,.png,.jpeg"
                    :limit="1"
                    :auto-upload="false"
                >
                    <i slot="default" class="el-icon-camera" />
                    <div slot="file" slot-scope="{file}">
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                        <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-delete"
                                @click="imageHandleRemove"
                            >
                                <i class="el-icon-delete" />
                            </span>
                        </span>
                    </div>
                </el-upload>
            </el-col>
            <el-col :span="20" :offset="2">
                <el-form ref="formData" class="key-add-form" :model="formData" label-position="top" :rules="rules">
                    <el-form-item :label="$t('keys.number')" prop="name">
                        <el-input v-model="formData.name" />
                    </el-form-item>

                    <el-form-item :label="$t('keys.description')">
                        <el-input
                            v-model="formData.description"
                            type="textarea"
                            :rows="3"
                        />
                    </el-form-item>
                    <ClientsAddressesDropdown ref="clientsAddressesDropdown" @change="formData.addressUuid = $event.uuid" />
                </el-form>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('loading.add_key')" @click="addKey">{{ $t('common.add') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    components: {
        ClientsAddressesDropdown: () => import(/* webpackChunkName: "ClientsAddressesDropdown" */ '@/components/ClientsAddressesDropdown'),
    },

    props: {
        modalAddKey: {
            type:    Boolean,
            default: false,
        },
        selectedClientUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            file:     null,
            formData: {
                name:        null,
                addressUuid: null,
                description: null,
            },
            rules: {
                name:        [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                addressUuid: [{ required: true, message: this.$t('common.required'), trigger: 'change' }],
            },
        };
    },

    methods: {
        async addKey() {
            await new Promise(resolve => this.$refs.formData.validate(valid => valid && resolve()));
            await new Promise(resolve => this.$refs.clientsAddressesDropdown.$refs.formData.validate(valid => valid && resolve()));

            try {
                this.$wait.start('loading.add_key');
                const { data } = await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/keys`, {
                    name:         this.formData.name,
                    description:  this.formData.description,
                    address_uuid: this.formData.addressUuid,
                });

                await this.uploadImage(data);

                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
                this.closeModal();
            } finally {
                this.$wait.end('loading.add_key');
            }
        },

        imageHandleChange(file) {
            this.file = file;
        },

        async uploadImage(keyUuid) {
            if (!this.file) return;
            try {
                const formData = new FormData();
                formData.append('file', this.file.raw);

                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${keyUuid}/upload_image`,
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
            } catch {
                console.log('Error while uploading image');
            }
        },

        imageHandleRemove() {
            this.$refs.upload.clearFiles();
            this.file = null;
        },

        closeModal() {
            axios.cancelAll();
            this.$emit('close');
        },

        clearData() {
            this.$refs.formData.resetFields();
            this.$refs.clientsAddressesDropdown.resetSelection();
            this.$refs.upload.clearFiles();
            this.file = null;
            this.formData = {
                name:        null,
                addressUuid: null,
                description: null,
            };
        },
    },
};
</script>
<style>
.key-add-form .el-select {
    width: 100%;
}
.hide-new-file .el-upload {
    display: none !important;
}
</style>
